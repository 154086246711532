<template>
  <div>
    <div class="ml-2 d-flex margin-auto">
      <div class="d-flex margin-unset">
        <label class="title-activities mr-3">
          {{ $t('views.client.details.activities.right-content.title') }}
        </label>
      </div>
      <div
        v-if="client.user && cardLevels.length > 0"
        class="d-flex justify-content-end"
      >
        <d-dropdown-items
          identifier="levels"
          :menus="menus"
          @on:add="onAdd"
          @on:edit="onEdit"
        />
      </div>
    </div>
    <b-row
      v-if="cardLevels.length === 0"
    >
      <b-col
        v-if="client.user === null"
        align="center"
        class="mt-3 text-no-levels"
        cols="12"
      >
        {{ $t('views.client.details.activities.right-content.no-levels') }}
      </b-col>
      <b-col
        v-else
        cols="12"
        class="mt-4"
        align="center"
      >
        <d-button
          text="views.client.details.activities.right-content.add-level"
          class="text-add-level pointer"
          icon="icofont icofont-plus"
          @on:button-click="onAdd"
        />
      </b-col>
    </b-row>
    <levels
      v-if="cardLevels.length > 0"
      :card-levels="cardLevels"
      class="mt-3"
    />
    <level-modal
      v-if="client.user"
      :display="display"
      :card-levels="cardLevels"
      :related-level="relatedLevel"
      :mode="mode"
      modal-id="level-modal"
      @on:card:selected="onCardSelect"
      @on:related-level:deleted="onDelete"
      @reload:related-activities="$emit('reload:related-activities')"
    />
    <d-modal
      :display="displayConfirmModal"
      :hide="hideConfirmModal"
      modal-id="edit-related-level"
      text="views.client.details.activities.right-content.level.confirm-modal"
      title="views.client.details.activities.right-content.level.confirm-title"
      @on:cancel="hideConfirmModal = !hideConfirmModal"
      @on:confirm="onConfirmEdit"
    />
  </div>
</template>
<script>

import RelatedActivities from "@/classes/doinsport/RelatedActivities";
import {
  getRelatedActivity,
  putRelatedActivity
} from "@api/doinsport/services/user-client/related-activities/related-activities.api";

export default {
  components: {
    Levels: () => import('./Levels'),
    LevelModal: () => import('@custom/clients/level/LevelModal'),
  },
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    client: {
      type: Object,
      default: this
    },
    cardLevels: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    mode: 'create',
    display: false,
    relatedLevel: null,
    tmpRelatedLevel: null,
    tmpSelectedCard: null,
    hideConfirmModal: false,
    displayConfirmModal: false,
    menus: [
      {
        name: 'add',
        icon: 'icofont icofont-plus'
      },
      {
        name: 'edit',
        icon: 'icofont icofont-ui-edit'
      }
    ]
  }),
  methods: {
    show() {
      this.display = !this.display;
    },
    onAdd() {
      this.mode = 'create';

      this.relatedLevel = new RelatedActivities();
      this.relatedLevel.user = this.client.user['@id'];

      this.show();
    },
    onConfirmEdit() {
      putRelatedActivity(this.relatedLevel.id, {activityLevels: [this.relatedLevel.activityLevels]})
        .then(() => {
          this.$emit('reload:related-activities')
          this.switchCard(this.tmpSelectedCard);
        })
        .finally(() => this.hideConfirmModal = !this.hideConfirmModal)
      ;
    },
    switchCard(card) {
      this.loadRelatedActivity(card.userRelatedActivityId)
        .then((response) => {
          this.relatedLevel = new RelatedActivities(response.data, {deserialize: true});
          this.saveLastRelatedLevel();
        })
        .finally(() => {
          this.$bus.$emit('on:card:selected');
        })
      ;
    },
    onCardSelect(card) {
      if (this.tmpRelatedLevel.id === this.relatedLevel.id && this.relatedLevel.activityLevels !== this.tmpRelatedLevel.activityLevels) {
        this.displayConfirmModal = !this.displayConfirmModal;
        this.tmpSelectedCard = card;
      } else {
        this.switchCard(card);
      }
    },
    onDelete() {
      if (this.cardLevels.length > 0) {
        this.loadRelatedActivity(this.cardLevels[0].userRelatedActivityId)
          .then((response) => {
            this.relatedLevel = new RelatedActivities(response.data, {deserialize: true});
            this.saveLastRelatedLevel();
          })
        ;
      } else {
        this.mode = 'create';

        this.relatedLevel = new RelatedActivities();
        this.relatedLevel.user = this.client.user['@id'];
      }
    },
    saveLastRelatedLevel() {
      this.tmpRelatedLevel = JSON.parse(JSON.stringify(this.relatedLevel));
    },
    onEdit() {
      this.mode = 'update';

      if (this.cardLevels.length > 0) {
        this.loadRelatedActivity(this.cardLevels[0].userRelatedActivityId)
          .then((response) => {
            this.relatedLevel = new RelatedActivities(response.data, {deserialize: true});
            this.saveLastRelatedLevel();
          })
          .finally(() => this.show())
        ;
      }
    },
    loadRelatedActivity(id) {
      this.relatedLevel = null;

      return getRelatedActivity(id);
    }
  }
}
</script>
<style scoped lang="scss">
.new-users-header {
  padding-bottom: 15px;
}

.title-activities {
  text-align: left;
  font: normal normal 900 21px Avenir;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.text-no-levels {
  font: normal normal 18px Avenir;
  letter-spacing: 0px;
  color: #A4AFB7;
  opacity: 1;
}

.text-add-level {
  font: normal normal 16px Avenir;
  color: #0B2772;
  opacity: 1;
}
</style>
