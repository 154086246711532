import {hydrate} from "@/utils/form";
import {refine} from "@/utils/classes";

export default class RelatedActivities {
  id = null;
  user = null;
  activity = null;
  activityLevels = null;

  constructor(object = null, options = null) {
    if (options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
    this.activityLevels = object.activityLevels ? [object.activityLevels] : [];
  }

  deserialize(object) {
    hydrate(this, object);
    refine(this);
    this.activityLevels = this.activityLevels[0];
  }
}
