import {_delete, _get, _post, _put} from "@api/doinsport/services/httpService";
import {USER_CLIENT_URL} from "@api/doinsport/services/user-client";

const RELATED_ACTIVITY_URL = `${USER_CLIENT_URL}/related-activities`;

export const postRelatedActivity = (relatedActivity) => _post(RELATED_ACTIVITY_URL, relatedActivity);

export const getRelatedActivity = (id) => _get(`${RELATED_ACTIVITY_URL}/${id}`);

export const deleteRelatedActivity = (id) => _delete(`${RELATED_ACTIVITY_URL}/${id}`);

export const putRelatedActivity = (id, data) => _put(`${RELATED_ACTIVITY_URL}/${id}`, data);
